var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    { staticClass: "dynamic_address_location_container" },
    [
      _c("td", [
        _c("strong", { domProps: { innerHTML: _vm._s(_vm.rendAddressTitle) } }),
      ]),
      _c(
        "td",
        { staticClass: "text-center" },
        [
          _c("b-form-checkbox", {
            attrs: {
              id: _vm.makeItemRef("dynamic_adrress_same_lcoation"),
              name: "dynamic_adrress_same_lcoation",
              size: "lg",
            },
            model: {
              value: _vm.isSameAddress,
              callback: function ($$v) {
                _vm.isSameAddress = $$v
              },
              expression: "isSameAddress",
            },
          }),
        ],
        1
      ),
      _c("td", [
        _vm.checkAddress
          ? _c("div", { staticClass: "title_address" }, [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.rendFilledAddress) },
              }),
            ])
          : _vm._e(),
      ]),
      _c("td", [
        !_vm.isSameAddress
          ? _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn bg-transparent border-0",
                    attrs: { size: "sm" },
                    on: { click: _vm.openAdresseModal },
                  },
                  [
                    _vm.checkAddress
                      ? _c(_vm.getLucideIcon("Edit"), {
                          tag: "component",
                          attrs: { color: "#225CBD", size: 20 },
                        })
                      : _c(_vm.getLucideIcon("PlusCircle"), {
                          tag: "component",
                          attrs: { color: "#225CBD", size: 20 },
                        }),
                  ],
                  1
                ),
                _vm.checkAddress
                  ? _c(
                      "b-button",
                      {
                        staticClass: "btn bg-transparent border-0 ml-1",
                        attrs: { size: "sm" },
                        on: { click: _vm.handleDeleteAddress },
                      },
                      [
                        _c(_vm.getLucideIcon("Trash2"), {
                          tag: "component",
                          attrs: { color: "#EA4E2C", size: 20 },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.rendAddressTitle,
            size: "xl",
            "ok-variant": "success",
            "ok-title": "Save",
            "cancel-title": "Cancel",
          },
          on: {
            ok: _vm.handleAddressSubmit,
            cancel: _vm.handleAdressModalCancel,
            hidden: _vm.handleAdressModalCancel,
          },
          model: {
            value: _vm.isAddressModalOpen,
            callback: function ($$v) {
              _vm.isAddressModalOpen = $$v
            },
            expression: "isAddressModalOpen",
          },
        },
        [
          _c("AddressForm", {
            attrs: { "edit-data": _vm.editAddressValue },
            on: { change: _vm.handleAdressChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }