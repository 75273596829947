var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.setNameLocationList, function (setName, index) {
        return _c(
          "b-row",
          { key: index },
          [
            _c(
              "b-col",
              [
                index === 0
                  ? _c(
                      "b-form-group",
                      { attrs: { label: _vm.FormMSG(620, "Set name") } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            placeholder: _vm.FormMSG(48, "Enter set name"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleChangeName($event, setName)
                            },
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleKeyDownEnter($event, setName)
                            },
                          },
                          model: {
                            value: setName.value,
                            callback: function ($$v) {
                              _vm.$set(setName, "value", $$v)
                            },
                            expression: "setName.value",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                index > 0
                  ? _c("b-form-input", {
                      staticClass: "mb-3",
                      attrs: { placeholder: _vm.FormMSG(48, "Enter set name") },
                      on: {
                        change: function ($event) {
                          return _vm.handleChangeName($event, setName)
                        },
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleKeyDownEnter($event, setName)
                        },
                      },
                      model: {
                        value: setName.value,
                        callback: function ($$v) {
                          _vm.$set(setName, "value", $$v)
                        },
                        expression: "setName.value",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm.showNewAddLocation
        ? _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c("b-col", [
                _c("div", { staticClass: "float-right" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn-transparent text-color-rhapsody-in-blue fs-14 fw-700",
                      attrs: { type: "button" },
                      on: { click: _vm.handleNewSet },
                    },
                    [
                      _c(_vm.getLucideIcon("PlusCircle"), {
                        tag: "component",
                        attrs: { size: 20, color: "#00A09C" },
                      }),
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(622, "Add set")) +
                          "\n\t\t\t\t"
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }