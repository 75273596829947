var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            left: "0",
            top: "0",
            right: "0",
            bottom: "0",
            "background-color": "white",
            "z-index": "6000",
            opacity: "0.5",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "'margin'": "0",
                position: "absolute",
                top: "50%",
                left: "50%",
                "-ms-transform": "translate(-50%, -50%)",
                transform: "translate(-50%, -50%)",
                display: "block",
              },
            },
            [
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "div",
                  { staticClass: "text-center mb-3" },
                  [_c("b-spinner", { attrs: { label: "Spinning" } })],
                  1
                ),
                _c("div", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.label) + " ..."),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }