<template>
	<div v-if="loading" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; background-color: white; z-index: 6000; opacity: 0.5">
		<div
			style="'margin':0 ; position: absolute; top: 50%; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); display: block"
		>
			<div class="text-center">
				<div class="text-center mb-3">
					<b-spinner label="Spinning" />
				</div>
				<div class="text-center">{{ label }} ...</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CustomLoadingPlanning',

	props: {
		loading: { type: Boolean, default: false, required: true },
		label: { type: String, required: true }
	}
};
</script>

<style scoped></style>
