var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row bootstrap-date-selection" },
    [
      _c(
        "label",
        { staticClass: "col-sm-3", attrs: { for: "location_date" } },
        [_vm._v(_vm._s(_vm.FormMSG(1, "Map picture")))]
      ),
      _c("div", { staticClass: "col-sm-9 px-0 d-flex align-items-center" }, [
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            !_vm.$isPwa()
              ? _c(
                  "b-button",
                  {
                    staticClass: "w-50",
                    staticStyle: { "margin-left": "-4px" },
                    attrs: { variant: "outline-primary" },
                    on: { click: _vm.openElectPicModalEnd },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(2, "Add map picture")) +
                        "\n\t\t\t"
                    ),
                  ]
                )
              : _vm._e(),
            _vm.$isPwa()
              ? _c(
                  "b-button",
                  {
                    staticClass: "w-50",
                    attrs: {
                      variant: "outline-primary",
                      disabled: _vm.isStartMobilePwaCaptureLoading,
                    },
                    on: { click: _vm.startMobilePwaCapture },
                  },
                  [
                    _vm.isStartMobilePwaCaptureLoading
                      ? _c("b-spinner", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { variant: "primary", small: "" },
                        })
                      : _vm._e(),
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(2, "Add map picture")) +
                        "\n\t\t\t"
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _vm.mapPicturePayload !== ""
              ? _c(
                  "b-button",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center float-right w-50",
                    attrs: { variant: "outline-secondary", size: "md" },
                    on: {
                      click: function ($event) {
                        return _vm.showPicture([_vm.mapPicturePayload])
                      },
                    },
                  },
                  [
                    _c(_vm.getLucideIcon(_vm.ICONS.PICTURE.name), {
                      tag: "component",
                      staticClass: "mr-2",
                      attrs: { size: 20 },
                    }),
                    _c("div", { staticStyle: { "margin-top": "1px" } }, [
                      _vm._v(_vm._s(_vm.FormMSG(3, "view picture"))),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("Capture", {
        attrs: {
          "auto-crop-capture": "",
          "only-one-crop-mode": "",
          parentId: _vm.parentId,
          parentType: "location",
          parentSubType: "location_map",
        },
        on: { change: _vm.handleMapPictureChange },
        model: {
          value: _vm.isMapPictureModalOpen,
          callback: function ($$v) {
            _vm.isMapPictureModalOpen = $$v
          },
          expression: "isMapPictureModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }