<template>
	<div>
		<b-row v-for="(setName, index) in setNameLocationList" :key="index">
			<b-col>
				<b-form-group v-if="index === 0" :label="FormMSG(620, 'Set name')">
					<b-form-input
						v-model="setName.value"
						:placeholder="FormMSG(48, 'Enter set name')"
						@change="handleChangeName($event, setName)"
						@keydown.enter="handleKeyDownEnter($event, setName)"
					/>
				</b-form-group>
				<b-form-input
					v-if="index > 0"
					v-model="setName.value"
					:placeholder="FormMSG(48, 'Enter set name')"
					class="mb-3"
					@change="handleChangeName($event, setName)"
					@keydown.enter="handleKeyDownEnter($event, setName)"
				/>
			</b-col>
		</b-row>
		<b-row v-if="showNewAddLocation" class="mt-2">
			<b-col>
				<div class="float-right">
					<button type="button" class="btn-transparent text-color-rhapsody-in-blue fs-14 fw-700" @click="handleNewSet">
						<component :is="getLucideIcon('PlusCircle')" :size="20" color="#00A09C" />
						{{ FormMSG(622, 'Add set') }}
					</button>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

const setNameList = ['setName1', 'setName2', 'setName3'];

export default {
	name: 'SetNames',

	mixins: [globalMixin, languageMessages],

	props: {
		dataToEdit: {
			type: Object,
			default: {},
			required: true
		}
	},

	data() {
		return {
			currentLocation: {},
			setNameLocationList: [],
			setNameAlreadyUsed: [],
			dataToEmit: {}
		};
	},

	watch: {
		dataToEdit: {
			handler(newVal) {
				this.currentLocation = newVal;
				this.setNameLocationList = [];
				this.dataToEmit = {};
				this.setNameAlreadyUsed = [];

				this.initSetNames(newVal);
				// if (this.setNameLocationList.length === 0) {
				// 	this.setNameLocationList.push({
				// 		value: '',
				// 		key: 'setName1'
				// 	});
				// 	this.setNameAlreadyUsed.push('setName1');
				// 	this.dataToEmit = {
				// 		setName1: this.FormMSG(254, 'Set name')
				// 	};
				//
				// 	this.emitEventChange();
				// }
			},
			immediate: true
		}
	},

	computed: {
		showNewAddLocation() {
			if (this.setNameLocationList.length < setNameList.length) {
				return true;
			}

			return false;
		}
	},

	methods: {
		handleKeyDownEnter(payload, address) {
			this.emitEventChange();
		},
		handleChangeName(payload, setName) {
			this.dataToEmit[setName.key] = payload;

			this.emitEventChange();
		},
		initSetNames(payload) {
			for (const [key, value] of Object.entries(payload)) {
				if (setNameList.includes(key) && value !== '') {
					this.setNameLocationList.push({
						value,
						key
					});
					this.setNameAlreadyUsed.push(key);
					this.dataToEmit = {
						...this.dataToEmit,
						key: value
					};
				}
			}
		},
		handleNewSet() {
			for (let i = 0; i < setNameList.length; i++) {
				if (!this.setNameAlreadyUsed.includes(setNameList[i])) {
					this.setNameAlreadyUsed.push(setNameList[i]);
					this.dataToEmit = {
						...this.dataToEmit,
						[setNameList[i]]: this.FormMSG(254, 'Set name')
					};

					break;
				}
			}

			this.emitEventChange();
		},
		emitEventChange() {
			this.$emit('set-names:change', this.dataToEmit);
		}
	}
};
</script>

<style scoped></style>
