<template>
	<div>
		<b-form-group :label="FormMSG(287, 'Main characteristic')">
			<v-select
				v-model="mainCharacteristicId"
				:options="mainCharacteristicOptions"
				taggable
				close-on-select
				@option:created="handleOptionCreated"
				@input="handleInput"
			>
				<template slot="option" slot-scope="option">
					<div class="d-flex">
						<div class="w-90">
							{{ option.label }}
						</div>
						<div v-if="mainCharacteristicId !== option.label">
							<button type="button" class="btn-transparent text-danger" @click.stop="handleClickDelete(option.label)">
								<component :is="getLucideIcon('Trash')" :size="20" />
							</button>
						</div>
					</div>
				</template>
			</v-select>
		</b-form-group>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getLocationMainCharacteristic, delMainCharacteristic } from '@/cruds/locations.crud';
import _ from 'lodash';

export default {
	name: 'SelectMainCharacteristic',

	props: {
		dataId: {
			type: [Number, String],
			default: 0
		}
	},

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			mainCharacteristicId: null,
			mainCharacteristicOptions: []
		};
	},

	watch: {
		dataId: {
			handler(newVal) {
				if (newVal === '') {
					this.mainCharacteristicId = null;
				} else {
					this.mainCharacteristicId = _.cloneDeep(newVal);
				}
			},
			immediate: true
		}
	},

	async created() {
		await this.getMainCharacteristics();
	},

	methods: {
		handleOptionCreated(payload) {
			this.mainCharacteristicOptions.push(payload);
		},
		async getMainCharacteristics() {
			const result = await getLocationMainCharacteristic();
			let mainCharacteristicOptions = [];

			result.map((option) => {
				mainCharacteristicOptions.push(option.description);
			});
			this.mainCharacteristicOptions = mainCharacteristicOptions;
		},
		async handleClickDelete(payload) {
			console.log(this.mainCharacteristicOptions);
			try {
				await delMainCharacteristic(payload);

				this.mainCharacteristicOptions = this.mainCharacteristicOptions.filter((option) => option !== payload);
			} catch (e) {
				if (e.graphQLErrors[0].status) {
					this.mainCharacteristicOptions = this.mainCharacteristicOptions.filter((option) => option !== payload);
				}
			}
		},
		handleInput(payload) {
			if (_.isNil(payload)) {
				this.emitEventChange('');
			} else {
				this.emitEventChange(payload);
			}
		},
		emitEventChange(payload) {
			this.$emit('select-main-characteristic:change', payload);
		}
	}
};
</script>

<style scoped></style>
