var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: _vm.FormMSG(287, "Main characteristic") } },
        [
          _c("v-select", {
            attrs: {
              options: _vm.mainCharacteristicOptions,
              taggable: "",
              "close-on-select": "",
            },
            on: {
              "option:created": _vm.handleOptionCreated,
              input: _vm.handleInput,
            },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (option) {
                  return [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "w-90" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(option.label) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm.mainCharacteristicId !== option.label
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn-transparent text-danger",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleClickDelete(option.label)
                                  },
                                },
                              },
                              [
                                _c(_vm.getLucideIcon("Trash"), {
                                  tag: "component",
                                  attrs: { size: 20 },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.mainCharacteristicId,
              callback: function ($$v) {
                _vm.mainCharacteristicId = $$v
              },
              expression: "mainCharacteristicId",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }