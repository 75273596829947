var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "details-document-package",
      class: {
        "container-layout": _vm.$screen.width > 576,
        "container-mobile": _vm.$screen.width <= 576,
      },
    },
    [
      _c("b-form", [
        _c(
          "div",
          [
            _c("custom-loading-planning", {
              attrs: {
                loading: _vm.isLoading,
                label: _vm.FormMSG(129, "Prepare data of location"),
              },
            }),
            _vm.$screen.width <= 567
              ? _c(
                  "h1",
                  {
                    staticClass: "main-page-title",
                    class: [{ "is-pwa": _vm.$isPwa() }],
                  },
                  [_vm._v("\n\t\t\t\t" + _vm._s(_vm.mobileTitle) + "\n\t\t\t")]
                )
              : _vm._e(),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { staticClass: "p-0", attrs: { cols: "12" } },
                  [
                    _c(
                      "b-card",
                      {
                        class: {
                          "card-border-blue-light": _vm.$screen.width > 567,
                          "bg-color-white pl-3 pr-3": _vm.$screen.width <= 567,
                        },
                        attrs: { "no-body": "" },
                      },
                      [
                        _vm.$screen.width > 567
                          ? _c(
                              "b-row",
                              {
                                staticClass:
                                  "back-with-title d-flex align-items-center py-1 pl-0",
                              },
                              [
                                _c("b-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "h2",
                                    { staticStyle: { "font-size": "1.5rem" } },
                                    [_vm._v(_vm._s(_vm.getTitle()))]
                                  ),
                                ]),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "d-flex justify-content-end",
                                    staticStyle: { "margin-top": "-8px" },
                                    attrs: { cols: "6" },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "w-138-px",
                                        attrs: {
                                          variant: "custom-outline-gray",
                                          disabled: _vm.isUpdtLocationLoading,
                                        },
                                        on: { click: _vm.handleClickCancel },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.FormMSG(658, "Cancel")) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "w-138-px ml-3",
                                        attrs: {
                                          variant: "primary",
                                          disabled: _vm.isUpdtLocationLoading,
                                        },
                                        on: { click: _vm.submitLocation },
                                      },
                                      [
                                        _vm.isUpdtLocationLoading
                                          ? _c("b-spinner", {
                                              attrs: { small: "" },
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.FormMSG(659, "Save")) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-transparent text-color-rhapsody-in-blue-2 ml-3",
                                        attrs: {
                                          type: "button",
                                          disabled: _vm.isUpdtLocationLoading,
                                        },
                                        on: { click: _vm.handleClickCancel },
                                      },
                                      [
                                        _c(_vm.getLucideIcon("X"), {
                                          tag: "component",
                                          attrs: { size: 30 },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-row",
                          { staticClass: "mt-3" },
                          [
                            _c(
                              "b-col",
                              [
                                _c("b-form-radio-group", {
                                  attrs: {
                                    id: "showOptionLocation",
                                    options: _vm.statusOptions,
                                    plain: "",
                                  },
                                  model: {
                                    value: _vm.currentLocation.status,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.currentLocation,
                                        "status",
                                        $$v
                                      )
                                    },
                                    expression: "currentLocation.status",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                { staticClass: "card-collapsible px-3 mt-3" },
                                [
                                  _vm.$screen.width <= 576
                                    ? _c("div", {
                                        staticClass: "divider-line mt-3 mb-3",
                                        staticStyle: {
                                          "margin-left": "-15px",
                                          "margin-right": "-15px",
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-3" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: _vm.detailsForGreenManager
                                              ? 12
                                              : 7,
                                            lg: _vm.detailsForGreenManager
                                              ? 12
                                              : 7,
                                            xl: _vm.detailsForGreenManager
                                              ? 12
                                              : 7,
                                          },
                                        },
                                        [
                                          _vm.$screen.width <= 576
                                            ? _c(
                                                "h2",
                                                { staticClass: "sub-title" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        651,
                                                        "General"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "fieldset",
                                            {
                                              staticClass: "my-0 py-0 pb-0",
                                              class: `${
                                                _vm.$screen.width <= 576
                                                  ? ""
                                                  : "scheduler-border"
                                              }`,
                                            },
                                            [
                                              _vm.$screen.width > 576
                                                ? _c(
                                                    "legend",
                                                    {
                                                      staticClass:
                                                        "text-color-rhapsody-in-blue-2",
                                                      class: `${
                                                        _vm.$screen.width <= 576
                                                          ? "card-inside"
                                                          : "scheduler-border"
                                                      }`,
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              651,
                                                              "General"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  class: {
                                                    "pt-3 pb-3":
                                                      _vm.$screen.width > 576,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-row",
                                                    { staticClass: "pb-3" },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: {
                                                            sm: "12",
                                                            md: _vm.detailsForGreenManager
                                                              ? 2
                                                              : 4,
                                                            lg: _vm.detailsForGreenManager
                                                              ? 2
                                                              : 4,
                                                            xl: _vm.detailsForGreenManager
                                                              ? 2
                                                              : 4,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.FormMSG(
                                                                    8,
                                                                    "Name"
                                                                  ),
                                                                "label-for":
                                                                  "location_name",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "location_name",
                                                                    type: "text",
                                                                    placeholder:
                                                                      _vm.FormMSG(
                                                                        9,
                                                                        "Enter name..."
                                                                      ),
                                                                    autocomplete:
                                                                      "off",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentLocation
                                                                        .setName,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.currentLocation,
                                                                          "setName",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "currentLocation.setName",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: {
                                                            sm: "12",
                                                            md: _vm.detailsForGreenManager
                                                              ? 2
                                                              : 4,
                                                            lg: _vm.detailsForGreenManager
                                                              ? 2
                                                              : 4,
                                                            xl: _vm.detailsForGreenManager
                                                              ? 2
                                                              : 4,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  this.FormMSG(
                                                                    255,
                                                                    "Type 1"
                                                                  ),
                                                                "label-for":
                                                                  "optionsForTypeOne",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-select",
                                                                {
                                                                  attrs: {
                                                                    id: "optionsForTypeOne",
                                                                    options:
                                                                      _vm.typeOneOptions,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentLocation
                                                                        .type1,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.currentLocation,
                                                                          "type1",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "currentLocation.type1",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: {
                                                            sm: "12",
                                                            md: _vm.detailsForGreenManager
                                                              ? 2
                                                              : 4,
                                                            lg: _vm.detailsForGreenManager
                                                              ? 2
                                                              : 4,
                                                            xl: _vm.detailsForGreenManager
                                                              ? 2
                                                              : 4,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  this.FormMSG(
                                                                    254,
                                                                    "Type 2"
                                                                  ),
                                                                "label-for":
                                                                  "optionsForTypeTwo",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-select",
                                                                {
                                                                  attrs: {
                                                                    id: "optionsForTypeTwo",
                                                                    options:
                                                                      _vm.optionsForLocationType,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .currentLocation
                                                                        .type,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.currentLocation,
                                                                          "type",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "currentLocation.type",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.detailsForGreenManager
                                                        ? _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "12",
                                                                md: "6",
                                                                lg: "6",
                                                                xl: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        458,
                                                                        "Address"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "MainAddressLocation",
                                                                    {
                                                                      attrs: {
                                                                        params:
                                                                          _vm.mainLocationAddress,
                                                                        "address-id":
                                                                          _vm
                                                                            .currentLocation[
                                                                            _vm
                                                                              .mainLocationAddress
                                                                              .name
                                                                          ],
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.handleDynamicAddressChange,
                                                                        delete:
                                                                          _vm.handleDynamicAddressDelete,
                                                                        "address-maplink-change":
                                                                          _vm.handleAddressMapLinkChange,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.detailsForGreenManager
                                                    ? _c(
                                                        "b-row",
                                                        {},
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              staticClass:
                                                                "col col-6",
                                                              attrs: {
                                                                label:
                                                                  _vm.FormMSG(
                                                                    459,
                                                                    "Production phase"
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-row",
                                                                {
                                                                  attrs: {
                                                                    sm: "12",
                                                                    md: "2",
                                                                    lg: "2",
                                                                    xl: "2",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              id: "preproduction",
                                                                              name: "preproduction",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .currentLocation
                                                                                  .preproduction,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.currentLocation,
                                                                                    "preproduction",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "currentLocation.preproduction",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  460,
                                                                                  "Preproduction"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-col",
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              id: "shooting",
                                                                              name: "shooting",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .currentLocation
                                                                                  .shooting,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.currentLocation,
                                                                                    "shooting",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "currentLocation.shooting",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  461,
                                                                                  "Shooting"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.currentLocation
                                                            .type1 === 1
                                                            ? _c(
                                                                "b-form-group",
                                                                {
                                                                  staticClass:
                                                                    "col",
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        466,
                                                                        "Is the building less than 30 years old ?"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-row",
                                                                    _vm._l(
                                                                      _vm.isBuildingLess30YoMenu,
                                                                      function (
                                                                        menu
                                                                      ) {
                                                                        return _c(
                                                                          "b-col",
                                                                          {
                                                                            key:
                                                                              menu.text +
                                                                              "AA",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-form-radio",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      menu.value,
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .currentLocation
                                                                                        .buildingLess30Yo,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.currentLocation,
                                                                                          "buildingLess30Yo",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "currentLocation.buildingLess30Yo",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      menu.text
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  !_vm.detailsForGreenManager
                                                    ? _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "12",
                                                                md: "4",
                                                                lg: "4",
                                                                xl: "4",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "select-main-characteristic",
                                                                {
                                                                  attrs: {
                                                                    "data-id":
                                                                      _vm
                                                                        .currentLocation
                                                                        .mainCharacteristic,
                                                                  },
                                                                  on: {
                                                                    "select-main-characteristic:change":
                                                                      _vm.handleSelectMainCharacteristicChange,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "12",
                                                                md: "8",
                                                                lg: "8",
                                                                xl: "8",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        289,
                                                                        "Description"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-textarea",
                                                                    {
                                                                      attrs: {
                                                                        rows: "3",
                                                                        "max-rows":
                                                                          "6",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .currentLocation
                                                                            .description,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.currentLocation,
                                                                              "description",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "currentLocation.description",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      !_vm.detailsForGreenManager
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "5",
                                                lg: "5",
                                                xl: "5",
                                              },
                                            },
                                            [
                                              _vm.$screen.width <= 576
                                                ? _c("div", {
                                                    staticClass:
                                                      "divider-line mt-3 mb-3",
                                                    staticStyle: {
                                                      "margin-left": "-15px",
                                                      "margin-right": "-15px",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.$screen.width <= 576
                                                ? _c(
                                                    "h2",
                                                    {
                                                      staticClass: "sub-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            650,
                                                            "Photos"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "fieldset",
                                                {
                                                  staticClass: "my-0 py-0 pb-0",
                                                  class: `${
                                                    _vm.$screen.width <= 576
                                                      ? ""
                                                      : "scheduler-border"
                                                  }`,
                                                  staticStyle: {
                                                    height: "100%",
                                                  },
                                                },
                                                [
                                                  _vm.$screen.width > 576
                                                    ? _c(
                                                        "legend",
                                                        {
                                                          staticClass:
                                                            "text-color-rhapsody-in-blue-2",
                                                          class: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "card-inside"
                                                              : "scheduler-border"
                                                          }`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  650,
                                                                  "Photos"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c("include-imgs", {
                                                            staticClass: "mt-4",
                                                            attrs: {
                                                              "parent-id":
                                                                _vm.currentLocation
                                                                  ? _vm
                                                                      .currentLocation
                                                                      .id
                                                                  : 0,
                                                              images:
                                                                _vm.currentLocation
                                                                  ? _vm
                                                                      .currentLocation
                                                                      .images
                                                                  : [],
                                                              "carousel-mode":
                                                                "",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.handleImagesChange,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  !_vm.detailsForGreenManager
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "7",
                                                lg: "7",
                                                xl: "7",
                                              },
                                            },
                                            [
                                              _vm.$screen.width <= 576
                                                ? _c("div", {
                                                    staticClass:
                                                      "divider-line mt-3 mb-3",
                                                    staticStyle: {
                                                      "margin-left": "-15px",
                                                      "margin-right": "-15px",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.$screen.width <= 576
                                                ? _c(
                                                    "h2",
                                                    {
                                                      staticClass:
                                                        "sub-title pb-0 mb-0",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            649,
                                                            "Location"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "fieldset",
                                                {
                                                  staticClass: "my-0 py-0 pb-0",
                                                  class: `${
                                                    _vm.$screen.width <= 576
                                                      ? ""
                                                      : "scheduler-border"
                                                  }`,
                                                  attrs: {
                                                    tyle: "height: 100%",
                                                  },
                                                },
                                                [
                                                  _vm.$screen.width > 576
                                                    ? _c(
                                                        "legend",
                                                        {
                                                          staticClass:
                                                            "text-color-rhapsody-in-blue-2",
                                                          class: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "card-inside"
                                                              : "scheduler-border"
                                                          }`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  649,
                                                                  "Location"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "pt-3 pb-3",
                                                    },
                                                    [
                                                      _c(
                                                        "b-row",
                                                        { staticClass: "pb-3" },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "12",
                                                                md: "6",
                                                                lg: "6",
                                                                xl: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        458,
                                                                        "Address"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "MainAddressLocation",
                                                                    {
                                                                      attrs: {
                                                                        params:
                                                                          _vm.mainLocationAddress,
                                                                        "address-id":
                                                                          _vm
                                                                            .currentLocation[
                                                                            _vm
                                                                              .mainLocationAddress
                                                                              .name
                                                                          ],
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.handleDynamicAddressChange,
                                                                        delete:
                                                                          _vm.handleDynamicAddressDelete,
                                                                        "address-maplink-change":
                                                                          _vm.handleAddressMapLinkChange,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "12",
                                                                md: "6",
                                                                lg: "6",
                                                                xl: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        459,
                                                                        "Production phase"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-row",
                                                                    {
                                                                      attrs: {
                                                                        "align-v":
                                                                          "center",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-col",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "b-form-checkbox",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      id: "preproduction",
                                                                                      size: "sm",
                                                                                      name: "preproduction",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .currentLocation
                                                                                          .preproduction,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.currentLocation,
                                                                                            "preproduction",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "currentLocation.preproduction",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          460,
                                                                                          "Preproduction"
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "b-col",
                                                                        [
                                                                          _c(
                                                                            "b-form-checkbox",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  size: "sm",
                                                                                  id: "shooting",
                                                                                  name: "shooting",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .currentLocation
                                                                                      .shooting,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.currentLocation,
                                                                                        "shooting",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "currentLocation.shooting",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      461,
                                                                                      "Shooting"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "12",
                                                                md: "6",
                                                                lg: "6",
                                                                xl: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        457,
                                                                        "Document attachments"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-input-group",
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          staticClass:
                                                                            "br-none input-readonly",
                                                                          attrs:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .documentSelected
                                                                                  .fileName,
                                                                              disabled:
                                                                                "",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-input-group-append",
                                                                        [
                                                                          _c(
                                                                            "b-input-group-text",
                                                                            {
                                                                              staticClass:
                                                                                "bg-color-white input-group-text-bg-white",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn-transparent text-color-aqua-velvet",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "button",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      _vm.viewDocument,
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      "File"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "b-dropdown",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      id: "dropdown-header",
                                                                                      size: "sm",
                                                                                      "no-caret":
                                                                                        "",
                                                                                      dropleft:
                                                                                        "",
                                                                                      "toggle-class":
                                                                                        "btn-transparent",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "template",
                                                                                    {
                                                                                      slot: "button-content",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        _vm.getLucideIcon(
                                                                                          "ChevronDown"
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          attrs:
                                                                                            {
                                                                                              size: 16,
                                                                                              fill: "#06263E",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm
                                                                                    .listDocuments
                                                                                    .length ===
                                                                                  0
                                                                                    ? _c(
                                                                                        "b-dropdown-item-button",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                              _vm._s(
                                                                                                _vm.FormMSG(
                                                                                                  547,
                                                                                                  "No document found"
                                                                                                )
                                                                                              ) +
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm._l(
                                                                                    _vm.listDocuments,
                                                                                    function (
                                                                                      doc,
                                                                                      index
                                                                                    ) {
                                                                                      return _c(
                                                                                        "b-dropdown-item-button",
                                                                                        {
                                                                                          key: index,
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.handleClickDocument(
                                                                                                  doc
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                              _vm._s(
                                                                                                doc.fileName
                                                                                              ) +
                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                2
                                                                              ),
                                                                              _c(
                                                                                "button",
                                                                                {
                                                                                  staticClass:
                                                                                    "btn-transparent text-color-aqua-velvet",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "button",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      _vm.openDocumentModal,
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      "PlusCircle"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 16,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "12",
                                                                md: "6",
                                                                lg: "6",
                                                                xl: "6",
                                                              },
                                                            },
                                                            [
                                                              _vm
                                                                .currentLocation
                                                                .type1 === 1
                                                                ? _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            466,
                                                                            "Is the building less than 30 years old ?"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-row",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "align-v":
                                                                                "center",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.isBuildingLess30YoMenu,
                                                                          function (
                                                                            menu
                                                                          ) {
                                                                            return _c(
                                                                              "b-col",
                                                                              {
                                                                                key:
                                                                                  menu.text +
                                                                                  "AA",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b-form-radio",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        size: "sm",
                                                                                        value:
                                                                                          menu.value,
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .currentLocation
                                                                                            .buildingLess30Yo,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.currentLocation,
                                                                                              "buildingLess30Yo",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "currentLocation.buildingLess30Yo",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          menu.text
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "5",
                                                lg: "5",
                                                xl: "5",
                                              },
                                            },
                                            [
                                              _vm.$screen.width <= 576
                                                ? _c("div", {
                                                    staticClass:
                                                      "divider-line mt-3 mb-3",
                                                    staticStyle: {
                                                      "margin-left": "-15px",
                                                      "margin-right": "-15px",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.$screen.width <= 576
                                                ? _c(
                                                    "h2",
                                                    {
                                                      staticClass: "sub-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            629,
                                                            "Contact"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "fieldset",
                                                {
                                                  staticClass: "my-0 py-0 pb-0",
                                                  class: `${
                                                    _vm.$screen.width <= 576
                                                      ? ""
                                                      : "scheduler-border"
                                                  }`,
                                                  attrs: {
                                                    tyle: "height: 100%",
                                                  },
                                                },
                                                [
                                                  _vm.$screen.width > 576
                                                    ? _c(
                                                        "legend",
                                                        {
                                                          staticClass:
                                                            "text-color-rhapsody-in-blue-2",
                                                          class: `${
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "card-inside"
                                                              : "scheduler-border"
                                                          }`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  629,
                                                                  "Contact"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      class: {
                                                        "pt-3 pb-3":
                                                          _vm.$screen.width >
                                                          576,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-row",
                                                        {
                                                          class:
                                                            _vm.$screen.width <=
                                                            576
                                                              ? "mb-2"
                                                              : "",
                                                        },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "fieldset",
                                                                {
                                                                  staticClass:
                                                                    "my-0 py-0 pb-0",
                                                                  class: `${
                                                                    _vm.$screen
                                                                      .width <=
                                                                    576
                                                                      ? "card-inside"
                                                                      : ""
                                                                  }`,
                                                                },
                                                                [
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? _c(
                                                                        "legend",
                                                                        {
                                                                          staticClass:
                                                                            "text-color-rhapsody-in-blue-2",
                                                                          class: `${
                                                                            _vm
                                                                              .$screen
                                                                              .width <=
                                                                            576
                                                                              ? "card-inside"
                                                                              : "scheduler-border"
                                                                          }`,
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                              _vm._s(
                                                                                _vm.FormMSG(
                                                                                  498,
                                                                                  "Supplier"
                                                                                )
                                                                              ) +
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class: {
                                                                        "pt-1 pb-1":
                                                                          _vm
                                                                            .$screen
                                                                            .width <=
                                                                          576,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "supplier-selector",
                                                                        {
                                                                          staticClass:
                                                                            "mb-3",
                                                                          attrs:
                                                                            {
                                                                              title: `${
                                                                                !_vm.isOwnerSupplierUpdate
                                                                                  ? _vm.FormMSG(
                                                                                      250,
                                                                                      "Add supplier details"
                                                                                    )
                                                                                  : _vm.FormMSG(
                                                                                      251,
                                                                                      "Update supplier details"
                                                                                    )
                                                                              }`,
                                                                              label:
                                                                                _vm
                                                                                  .$screen
                                                                                  .width >
                                                                                576
                                                                                  ? _vm.FormMSG(
                                                                                      481,
                                                                                      "Supplier"
                                                                                    )
                                                                                  : "",
                                                                              placeholder:
                                                                                _vm.FormMSG(
                                                                                  13,
                                                                                  "Filter by name"
                                                                                ),
                                                                              addlabel:
                                                                                _vm.FormMSG(
                                                                                  5442,
                                                                                  "New supplier"
                                                                                ),
                                                                              "update-label":
                                                                                _vm.FormMSG(
                                                                                  40,
                                                                                  "Update"
                                                                                ),
                                                                              "show-map": false,
                                                                              type: "owner",
                                                                              "supplier-id":
                                                                                _vm.ownerSupplierId,
                                                                              "use-new-design":
                                                                                "",
                                                                              version:
                                                                                "1.0",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.handleOwnerSupplierChange,
                                                                            "supplier:unselected":
                                                                              _vm.onOwnerSupplierUnselected,
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _vm.currentLocation
                                                            .owner
                                                            .companyType !== 0
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "12",
                                                                    md: "3",
                                                                    lg: "3",
                                                                    xl: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            452,
                                                                            "Contact name"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .currentLocation
                                                                                  .owner
                                                                                  .contactName,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .currentLocation
                                                                                      .owner,
                                                                                    "contactName",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "currentLocation.owner.contactName",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "12",
                                                                md: "6",
                                                                lg: "6",
                                                                xl: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        400,
                                                                        "Contact email"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .currentLocation
                                                                            .owner
                                                                            .email,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .currentLocation
                                                                                .owner,
                                                                              "email",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "currentLocation.owner.email",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                sm: "12",
                                                                md:
                                                                  _vm
                                                                    .currentLocation
                                                                    .owner
                                                                    .companyType !==
                                                                  0
                                                                    ? 3
                                                                    : 6,
                                                                lg:
                                                                  _vm
                                                                    .currentLocation
                                                                    .owner
                                                                    .companyType !==
                                                                  0
                                                                    ? 3
                                                                    : 6,
                                                                xl:
                                                                  _vm
                                                                    .currentLocation
                                                                    .owner
                                                                    .companyType !==
                                                                  0
                                                                    ? 3
                                                                    : 6,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        399,
                                                                        "Contact phone"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .currentLocation
                                                                            .owner
                                                                            .phone,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .currentLocation
                                                                                .owner,
                                                                              "phone",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "currentLocation.owner.phone",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: _vm.detailsForGreenManager
                                              ? 12
                                              : 7,
                                            lg: _vm.detailsForGreenManager
                                              ? 12
                                              : 7,
                                            xl: _vm.detailsForGreenManager
                                              ? 12
                                              : 7,
                                          },
                                        },
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _vm.$screen.width <= 576
                                                    ? _c("div", {
                                                        staticClass:
                                                          "divider-line mt-3 mb-3",
                                                        staticStyle: {
                                                          "margin-left":
                                                            "-15px",
                                                          "margin-right":
                                                            "-15px",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm.$screen.width <= 576
                                                    ? _c(
                                                        "h2",
                                                        {
                                                          staticClass:
                                                            "sub-title pb-0 mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                602,
                                                                "Filming"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "fieldset",
                                                    {
                                                      staticClass:
                                                        "my-0 py-0 pb-0",
                                                      class: `${
                                                        _vm.$screen.width <= 576
                                                          ? ""
                                                          : "scheduler-border"
                                                      }`,
                                                      attrs: {
                                                        tyle: "height: 100%",
                                                      },
                                                    },
                                                    [
                                                      _vm.$screen.width > 576
                                                        ? _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "text-color-rhapsody-in-blue-2",
                                                              class: `${
                                                                _vm.$screen
                                                                  .width <= 576
                                                                  ? "card-inside"
                                                                  : "scheduler-border"
                                                              }`,
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      602,
                                                                      "Filming"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pt-3 pb-5",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-row",
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        sm: "12",
                                                                        md: "4",
                                                                        lg: "4",
                                                                        xl: "4",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.FormMSG(
                                                                                  603,
                                                                                  "Filming area"
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-input-group",
                                                                            [
                                                                              _c(
                                                                                "b-form-input",
                                                                                {
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .currentLocation
                                                                                          .filmingArea,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.currentLocation,
                                                                                            "filmingArea",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "currentLocation.filmingArea",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "b-input-group-append",
                                                                                [
                                                                                  _c(
                                                                                    "b-input-group-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "bg-color-north-wind text-color-white",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "font-weight-bold",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "m"
                                                                                          ),
                                                                                          _c(
                                                                                            "sup",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "2"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        sm: "12",
                                                                        md: "4",
                                                                        lg: "4",
                                                                        xl: "4",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.FormMSG(
                                                                                  26,
                                                                                  "Ancillary spaces area"
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-input-group",
                                                                            [
                                                                              _c(
                                                                                "b-form-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      type: "number",
                                                                                      step: "1",
                                                                                      min: "0",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .currentLocation
                                                                                          .surfaceAreaOfFixtures,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.currentLocation,
                                                                                            "surfaceAreaOfFixtures",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "currentLocation.surfaceAreaOfFixtures",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "b-input-group-append",
                                                                                [
                                                                                  _c(
                                                                                    "b-input-group-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "bg-color-north-wind text-color-white",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "font-weight-bold",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "m"
                                                                                          ),
                                                                                          _c(
                                                                                            "sup",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "2"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        sm: "12",
                                                                        md: "4",
                                                                        lg: "4",
                                                                        xl: "4",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.FormMSG(
                                                                                  685,
                                                                                  "Number of days used"
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .currentLocation
                                                                                      .totNbOfDaysOccupation,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.currentLocation,
                                                                                        "totNbOfDaysOccupation",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "currentLocation.totNbOfDaysOccupation",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm.isFilm() &&
                                                                  _vm.detailsForGreenManager
                                                                    ? _c(
                                                                        "b-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              sm: "12",
                                                                              md: "3",
                                                                              lg: "3",
                                                                              xl: "3",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-group",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    _vm.FormMSG(
                                                                                      604,
                                                                                      "Location prep date"
                                                                                    ),
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-form-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      type: "date",
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      _vm.handleChangeGetInDate,
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .currentLocation
                                                                                          .getInDate,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.currentLocation,
                                                                                            "getInDate",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "currentLocation.getInDate",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.isFilm() &&
                                                                  _vm.detailsForGreenManager
                                                                    ? _c(
                                                                        "b-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              sm: "12",
                                                                              md: "3",
                                                                              lg: "3",
                                                                              xl: "3",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-group",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    _vm.FormMSG(
                                                                                      607,
                                                                                      "Location wrap date"
                                                                                    ),
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-form-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      min: _vm
                                                                                        .currentLocation
                                                                                        .getInDate,
                                                                                      type: "date",
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      _vm.handleChangeGetOutDate,
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .currentLocation
                                                                                          .getOutDate,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.currentLocation,
                                                                                            "getOutDate",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "currentLocation.getOutDate",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              !_vm.detailsForGreenManager
                                                                ? _c(
                                                                    "b-row",
                                                                    [
                                                                      _vm.isFilm()
                                                                        ? _c(
                                                                            "b-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  sm: "12",
                                                                                  md: "6",
                                                                                  lg: "6",
                                                                                  xl: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-form-group",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        _vm.FormMSG(
                                                                                          604,
                                                                                          "Location prep date"
                                                                                        ),
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b-form-input",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          type: "date",
                                                                                        },
                                                                                      on: {
                                                                                        change:
                                                                                          _vm.handleChangeGetInDate,
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .currentLocation
                                                                                              .getInDate,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.currentLocation,
                                                                                                "getInDate",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "currentLocation.getInDate",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.isFilm()
                                                                        ? _c(
                                                                            "b-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  sm: "12",
                                                                                  md: "6",
                                                                                  lg: "6",
                                                                                  xl: "6",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-form-group",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        _vm.FormMSG(
                                                                                          607,
                                                                                          "Location wrap date"
                                                                                        ),
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b-form-input",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          min: _vm
                                                                                            .currentLocation
                                                                                            .getInDate,
                                                                                          type: "date",
                                                                                        },
                                                                                      on: {
                                                                                        change:
                                                                                          _vm.handleChangeGetOutDate,
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .currentLocation
                                                                                              .getOutDate,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.currentLocation,
                                                                                                "getOutDate",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "currentLocation.getOutDate",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                          !_vm.detailsForGreenManager
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-row",
                                                                    [
                                                                      _c(
                                                                        "b-col",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "clearfix d-flex fs-14 fw-700 w-100",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "min-width":
                                                                                        "9rem",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          574,
                                                                                          "Filming dates"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "dot-divider",
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex w-100",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b-badge",
                                                                                    {
                                                                                      staticClass:
                                                                                        "badge-variant",
                                                                                      attrs:
                                                                                        {
                                                                                          pill: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "badge-notification",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.filmingDatesNumber
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "w-94 ml-3",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.FormMSG(
                                                                                            575,
                                                                                            "filming days"
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-row",
                                                                    {
                                                                      staticClass:
                                                                        "mt-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              sm: "12",
                                                                              md: "8",
                                                                              lg: "8",
                                                                              xl: "8",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "Calendar",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  "min-date":
                                                                                    _vm.minDateValue,
                                                                                  "max-date":
                                                                                    _vm.maxDateValue,
                                                                                  attributes:
                                                                                    _vm.attributes,
                                                                                  "from-page":
                                                                                    _vm.dateToShow,
                                                                                  locale:
                                                                                    _vm.lang,
                                                                                  "is-expanded":
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                dayclick:
                                                                                  _vm.handleDayClick,
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm
                                                                        .$screen
                                                                        .width >
                                                                      576
                                                                        ? _c(
                                                                            "b-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  sm: "12",
                                                                                  md: "4",
                                                                                  lg: "4",
                                                                                  xl: "4",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "fs-14 fw-500 p-2 list-selected-dates-form-time-card",
                                                                                },
                                                                                [
                                                                                  _vm
                                                                                    .shootingDatesFiltered
                                                                                    .length >
                                                                                  0
                                                                                    ? _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            "perfect-scrollbar",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  height:
                                                                                                    "255px",
                                                                                                },
                                                                                            },
                                                                                            _vm._l(
                                                                                              _vm.shootingDatesFiltered,
                                                                                              function (
                                                                                                day,
                                                                                                index
                                                                                              ) {
                                                                                                return _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    key: index,
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "fs-14",
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                            _vm._s(
                                                                                                              _vm._f(
                                                                                                                "formatDate"
                                                                                                              )(
                                                                                                                day.date,
                                                                                                                "dddd MMMM DD, YYYY"
                                                                                                              )
                                                                                                            ) +
                                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              }
                                                                                            ),
                                                                                            0
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-center",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                  _vm._s(
                                                                                                    _vm.FormMSG(
                                                                                                      531,
                                                                                                      "No date selected"
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          !_vm.detailsForGreenManager
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-row",
                                                                    {
                                                                      staticClass:
                                                                        "mt-3",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-col",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-inline-block fs-14 fw-700",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      613,
                                                                                      "Off-set locations"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-inline-block fs-14 fw-400 text-color-rhapsody-in-blue-2",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      614,
                                                                                      "(if different than location address)"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "offset-locations",
                                                                    {
                                                                      attrs: {
                                                                        "data-to-edit":
                                                                          _vm.currentLocation,
                                                                      },
                                                                      on: {
                                                                        "offset-locations:change":
                                                                          _vm.handleOffsetLocationsChange,
                                                                        "offset-locations:remove":
                                                                          _vm.handleOffsetLocationsRemove,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _vm.$screen.width <= 576
                                                    ? _c("div", {
                                                        staticClass:
                                                          "divider-line mt-3 mb-3",
                                                        staticStyle: {
                                                          "margin-left":
                                                            "-15px",
                                                          "margin-right":
                                                            "-15px",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm.$screen.width <= 576
                                                    ? _c(
                                                        "h2",
                                                        {
                                                          staticClass:
                                                            "sub-title pb-0 mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  700,
                                                                  "Additional info"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "fieldset",
                                                    {
                                                      staticClass:
                                                        "my-0 py-0 pb-0",
                                                      class: `${
                                                        _vm.$screen.width <= 576
                                                          ? ""
                                                          : "scheduler-border"
                                                      }`,
                                                      attrs: {
                                                        tyle: "height: 100%",
                                                      },
                                                    },
                                                    [
                                                      _vm.$screen.width > 576
                                                        ? _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "text-color-rhapsody-in-blue-2",
                                                              class: `${
                                                                _vm.$screen
                                                                  .width <= 576
                                                                  ? "card-inside"
                                                                  : "scheduler-border"
                                                              }`,
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      700,
                                                                      "Additional info"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pt-3 pb-3",
                                                        },
                                                        [
                                                          _c(
                                                            "b-row",
                                                            {
                                                              staticClass:
                                                                "pb-3",
                                                            },
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: "12",
                                                                    md: _vm.detailsForGreenManager
                                                                      ? 3
                                                                      : 6,
                                                                    lg: _vm.detailsForGreenManager
                                                                      ? 3
                                                                      : 6,
                                                                    xl: _vm.detailsForGreenManager
                                                                      ? 3
                                                                      : 6,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            701,
                                                                            "Do you use grid energy ?"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-row",
                                                                        [
                                                                          _c(
                                                                            "b-col",
                                                                            [
                                                                              _c(
                                                                                "b-form-radio",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      value: true,
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .currentLocation
                                                                                          .useGridEnergy,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.currentLocation,
                                                                                            "useGridEnergy",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "currentLocation.useGridEnergy",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          467,
                                                                                          "Yes"
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "b-col",
                                                                            [
                                                                              _c(
                                                                                "b-form-radio",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      value: false,
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .currentLocation
                                                                                          .useGridEnergy,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.currentLocation,
                                                                                            "useGridEnergy",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "currentLocation.useGridEnergy",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                      _vm._s(
                                                                                        _vm.FormMSG(
                                                                                          468,
                                                                                          "No"
                                                                                        )
                                                                                      ) +
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm
                                                                .currentLocation
                                                                .type1 === 0
                                                                ? [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          sm: "12",
                                                                          md: "3",
                                                                          lg: "3",
                                                                          xl: "3",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-group",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  _vm.FormMSG(
                                                                                    710,
                                                                                    "Do you use ancillary space ?"
                                                                                  ),
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-row",
                                                                              [
                                                                                _c(
                                                                                  "b-col",
                                                                                  [
                                                                                    _c(
                                                                                      "b-form-radio",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            value: true,
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .currentLocation
                                                                                                .useAncillarySpace,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.currentLocation,
                                                                                                  "useAncillarySpace",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "currentLocation.useAncillarySpace",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                            _vm._s(
                                                                                              _vm.FormMSG(
                                                                                                467,
                                                                                                "Yes"
                                                                                              )
                                                                                            ) +
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "b-col",
                                                                                  [
                                                                                    _c(
                                                                                      "b-form-radio",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            value: false,
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            _vm.handleChangeUseAncillarySpace,
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .currentLocation
                                                                                                .useAncillarySpace,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.currentLocation,
                                                                                                  "useAncillarySpace",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "currentLocation.useAncillarySpace",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                            _vm._s(
                                                                                              _vm.FormMSG(
                                                                                                468,
                                                                                                "No"
                                                                                              )
                                                                                            ) +
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          ),
                                                          _vm.issetAdditionalInfo
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "b-row",
                                                                    [
                                                                      _c(
                                                                        "b-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cols: _vm.detailsForGreenManager
                                                                                ? 3
                                                                                : 6,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-checkbox",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  id: "placeHeated",
                                                                                  name: "placeHeated",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .currentLocation
                                                                                      .placeHeated,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.currentLocation,
                                                                                        "placeHeated",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "currentLocation.placeHeated",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .currentLocation
                                                                                .type1 ===
                                                                              1
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                          _vm._s(
                                                                                            _vm.FormMSG(
                                                                                              702,
                                                                                              "Place heated"
                                                                                            )
                                                                                          ) +
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm
                                                                                    .currentLocation
                                                                                    .type1 ===
                                                                                  0
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                          _vm._s(
                                                                                            _vm.FormMSG(
                                                                                              708,
                                                                                              "Ancillary place heated"
                                                                                            )
                                                                                          ) +
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                          _vm
                                                                            .currentLocation
                                                                            .placeHeated
                                                                            ? _c(
                                                                                "b-form-group",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        _vm.FormMSG(
                                                                                          704,
                                                                                          "Heating type"
                                                                                        ),
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-select",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          options:
                                                                                            _vm.heatingType,
                                                                                          label:
                                                                                            "text",
                                                                                          reduce:
                                                                                            (
                                                                                              option
                                                                                            ) =>
                                                                                              option.value,
                                                                                          placeholder:
                                                                                            _vm.FormMSG(
                                                                                              700,
                                                                                              "Select a type"
                                                                                            ),
                                                                                          clearable: false,
                                                                                          selectable:
                                                                                            _vm.selectableTypeItem,
                                                                                        },
                                                                                      scopedSlots:
                                                                                        _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key: "option",
                                                                                              fn: function (
                                                                                                option
                                                                                              ) {
                                                                                                return [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      class: `${
                                                                                                        (option.disabled &&
                                                                                                          option.disabled ===
                                                                                                            true) ||
                                                                                                        (option.selectable &&
                                                                                                          option.selectable ===
                                                                                                            true)
                                                                                                          ? "text-bold"
                                                                                                          : "text-color ml-3"
                                                                                                      }`,
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                          _vm._s(
                                                                                                            option.text
                                                                                                          ) +
                                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          false,
                                                                                          1086310716
                                                                                        ),
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .currentLocation
                                                                                              .heatingType,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.currentLocation,
                                                                                                "heatingType",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "currentLocation.heatingType",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm.heatingTypeInvalid
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "invalid-feedback",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.FormMSG(
                                                                                                600,
                                                                                                "This field is required"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "b-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cols: _vm.detailsForGreenManager
                                                                                ? 4
                                                                                : 6,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-checkbox",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  id: "placeAirConditionned",
                                                                                  name: "placeAirConditionned",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  _vm.handleChangePlaceAirConditionned,
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .currentLocation
                                                                                      .placeAirConditionned,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.currentLocation,
                                                                                        "placeAirConditionned",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "currentLocation.placeAirConditionned",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .currentLocation
                                                                                .type1 ===
                                                                              1
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                          _vm._s(
                                                                                            _vm.FormMSG(
                                                                                              703,
                                                                                              "Place air-conditionned"
                                                                                            )
                                                                                          ) +
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm
                                                                                    .currentLocation
                                                                                    .type1 ===
                                                                                  0
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                          _vm._s(
                                                                                            _vm.FormMSG(
                                                                                              709,
                                                                                              "Ancillary place air-conditionned"
                                                                                            )
                                                                                          ) +
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                          _vm
                                                                            .currentLocation
                                                                            .placeAirConditionned
                                                                            ? _c(
                                                                                "b-form-group",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        _vm.FormMSG(
                                                                                          705,
                                                                                          "No. of air conditionners"
                                                                                        ),
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex align-items-center",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-input-group",
                                                                                        [
                                                                                          _c(
                                                                                            "b-form-input",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  disabled:
                                                                                                    _vm.nbOfAirConditionersDisable,
                                                                                                },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .currentLocation
                                                                                                      .nbOfAirConditioners,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm.currentLocation,
                                                                                                        "nbOfAirConditioners",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "currentLocation.nbOfAirConditioners",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                          _c(
                                                                                            "b-input-group-append",
                                                                                            [
                                                                                              _c(
                                                                                                "b-input-group-text",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "bg-color-north-wind text-color-white",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "font-weight-bold",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.FormMSG(
                                                                                                            706,
                                                                                                            "units"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "b-form-checkbox",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mx-3",
                                                                                          attrs:
                                                                                            {
                                                                                              switch:
                                                                                                "",
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .currentLocation
                                                                                                  .estimation,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm.currentLocation,
                                                                                                    "estimation",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "currentLocation.estimation",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.FormMSG(
                                                                                                707,
                                                                                                "Estimation"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm.nbOfAirConditionersInvalid
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "invalid-feedback",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.FormMSG(
                                                                                        600,
                                                                                        "This field is required"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _vm.$screen.width <= 576
                                                    ? _c("div", {
                                                        staticClass:
                                                          "divider-line mt-3 mb-3",
                                                        staticStyle: {
                                                          "margin-left":
                                                            "-15px",
                                                          "margin-right":
                                                            "-15px",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm.$screen.width <= 576
                                                    ? _c(
                                                        "h2",
                                                        {
                                                          staticClass:
                                                            "sub-title pb-0 mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  652,
                                                                  "Energy consumption"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "fieldset",
                                                    {
                                                      staticClass:
                                                        "my-0 py-0 pb-0",
                                                      class: `${
                                                        _vm.$screen.width <= 576
                                                          ? ""
                                                          : "scheduler-border"
                                                      }`,
                                                      attrs: {
                                                        tyle: "height: 100%",
                                                      },
                                                    },
                                                    [
                                                      _vm.$screen.width > 576
                                                        ? _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "text-color-rhapsody-in-blue-2",
                                                              class: `${
                                                                _vm.$screen
                                                                  .width <= 576
                                                                  ? "card-inside"
                                                                  : "scheduler-border"
                                                              }`,
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      652,
                                                                      "Energy consumption"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pt-3 pb-3",
                                                        },
                                                        [
                                                          _c(
                                                            "view-consumption-energy",
                                                            {
                                                              attrs: {
                                                                location:
                                                                  _vm.currentLocation,
                                                                isLocation: true,
                                                              },
                                                              on: {
                                                                "view-consumption-energy:add-edit":
                                                                  _vm.handleClickeAddEditConsumption,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      !_vm.detailsForGreenManager
                                        ? _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                sm: "12",
                                                md: "5",
                                                lg: "5",
                                                xl: "5",
                                              },
                                            },
                                            [
                                              _c(
                                                "fieldset",
                                                {
                                                  staticClass: "my-0 py-0 pb-0",
                                                  class: `${
                                                    _vm.$screen.width <= 576
                                                      ? "card-inside bg-color-white border-ligth-starlight"
                                                      : "scheduler-border"
                                                  }`,
                                                  attrs: {
                                                    tyle: "height: 100%",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "legend",
                                                    {
                                                      staticClass:
                                                        "text-color-rhapsody-in-blue-2",
                                                      class: `${
                                                        _vm.$screen.width <= 576
                                                          ? "card-inside"
                                                          : "scheduler-border"
                                                      }`,
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              619,
                                                              "Sets"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "pt-3 pb-5",
                                                    },
                                                    [
                                                      _c("set-names", {
                                                        attrs: {
                                                          "data-to-edit":
                                                            _vm.currentLocation,
                                                        },
                                                        on: {
                                                          "set-names:change":
                                                            _vm.handleSetNamesChange,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.$screen.width <= 567
              ? _c(
                  "b-row",
                  { staticClass: "footer-fixed" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "font-weight": "700",
                            },
                            attrs: {
                              variant: "outline-primary",
                              block: "",
                              disabled: _vm.isUpdtLocationLoading,
                            },
                            on: { click: _vm.handleClickCancel },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(85, "Cancel")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "font-weight": "700",
                            },
                            attrs: {
                              variant: "primary",
                              block: "",
                              disabled: _vm.isUpdtLocationLoading,
                            },
                            on: { click: _vm.submitLocation },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(86, "Save")) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("location-document-modal", {
        attrs: {
          "location-id": _vm.currentLocation ? +_vm.currentLocation.id : 0,
        },
        on: { "location-document:hidden": _vm.onHideDocumentModal },
        model: {
          value: _vm.isOpenDocumentModal,
          callback: function ($$v) {
            _vm.isOpenDocumentModal = $$v
          },
          expression: "isOpenDocumentModal",
        },
      }),
      _c("location-entry-carbon-dialog", {
        attrs: {
          open: _vm.showLocationEntryCarbon,
          location: _vm.currentLocation,
          carbons: _vm.carbons,
        },
        on: {
          "location-entry-carbon:submit-success":
            _vm.handleLocationEntryCarbonSubmitSuccess,
          "location-entry-carbon:close": _vm.handleLocationEntryCarbonClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }