var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.addressLocationList, function (address, index) {
        return _c("div", { key: index, staticClass: "d-flex mt-3" }, [
          _c(
            "div",
            { staticClass: "w-90" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "4" } },
                    [
                      index === 0
                        ? _c(
                            "b-form-group",
                            {
                              staticClass: "mb-0",
                              attrs: { label: _vm.FormMSG(615, "Name/dept") },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: _vm.FormMSG(458, "Enter a name"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleChangeName($event, address)
                                  },
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.handleKeyDownEnter(
                                      $event,
                                      address
                                    )
                                  },
                                },
                                model: {
                                  value: address.address.name,
                                  callback: function ($$v) {
                                    _vm.$set(address.address, "name", $$v)
                                  },
                                  expression: "address.address.name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      index > 0
                        ? _c("b-form-input", {
                            attrs: {
                              placeholder: _vm.FormMSG(458, "Enter a name"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeName($event, address)
                              },
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleKeyDownEnter($event, address)
                              },
                            },
                            model: {
                              value: address.address.name,
                              callback: function ($$v) {
                                _vm.$set(address.address, "name", $$v)
                              },
                              expression: "address.address.name",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "8" } },
                    [
                      index === 0
                        ? _c(
                            "b-form-group",
                            {
                              staticClass: "mb-0",
                              attrs: { label: _vm.FormMSG(616, "Address") },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    staticClass: "br-none",
                                    attrs: {
                                      value: _vm.formatAddress(address.address),
                                      placeholder: _vm.FormMSG(
                                        459,
                                        "No selected address..."
                                      ),
                                    },
                                  }),
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-white input-group-text-bg-white",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent text-color-fly-away",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rendGoogleMapLink(
                                                address.address
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("MapPin"), {
                                            tag: "component",
                                            attrs: { size: 20 },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent text-color-aqua-velvet",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleClickAddAddress(
                                                address
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("PlusCircle"), {
                                            tag: "component",
                                            attrs: { size: 20 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      index > 0
                        ? _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "br-none",
                                attrs: {
                                  value: _vm.formatAddress(address.address),
                                  placeholder: _vm.FormMSG(
                                    459,
                                    "No selected address..."
                                  ),
                                },
                              }),
                              _c(
                                "b-input-group-text",
                                {
                                  staticClass:
                                    "bg-color-white input-group-text-bg-white",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-transparent text-color-fly-away",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rendGoogleMapLink(
                                            address.address
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(_vm.getLucideIcon("MapPin"), {
                                        tag: "component",
                                        attrs: { size: 20 },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-transparent text-color-aqua-velvet",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickAddAddress(
                                            address
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(_vm.getLucideIcon("PlusCircle"), {
                                        tag: "component",
                                        attrs: { size: 20 },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "text-center ml-3",
              class: index === 0 ? "pt-29" : "pt-1",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn-transparent text-color-burning-tomato-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickRemove(address)
                    },
                  },
                },
                [
                  _c(_vm.getLucideIcon("MinusCircle"), {
                    tag: "component",
                    attrs: { size: 20 },
                  }),
                ],
                1
              ),
            ]
          ),
        ])
      }),
      _vm.showNewOffsetLocationBtn
        ? _c("div", { staticClass: "w-90 mt-3" }, [
            _c("div", { staticClass: "float-right" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn-transparent text-color-rhapsody-in-blue fs-14 fw-700",
                  attrs: { type: "button" },
                  on: { click: _vm.handleNewOffsetLocation },
                },
                [
                  _c(_vm.getLucideIcon("PlusCircle"), {
                    tag: "component",
                    attrs: { size: 20, color: "#00A09C" },
                  }),
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(617, "New off-set location")) +
                      "\n\t\t\t"
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(547, "Off-set location"),
            size: "xl",
            "ok-variant": "success",
            "ok-title": _vm.FormMSG(2, "Save"),
            "cancel-title": _vm.FormMSG(3, "Cancel"),
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
          model: {
            value: _vm.openOffsetLocationModal,
            callback: function ($$v) {
              _vm.openOffsetLocationModal = $$v
            },
            expression: "openOffsetLocationModal",
          },
        },
        [
          _c("AddressForm", {
            attrs: { "edit-data": _vm.addressLocationToEdit.address },
            on: { change: _vm.handleAddressFormChange },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }