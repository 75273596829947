<template>
	<div>
		<div v-for="(address, index) in addressLocationList" :key="index" class="d-flex mt-3">
			<div class="w-90">
				<b-row>
					<b-col cols="4">
						<b-form-group v-if="index === 0" :label="FormMSG(615, 'Name/dept')" class="mb-0">
							<b-form-input
								v-model="address.address.name"
								:placeholder="FormMSG(458, 'Enter a name')"
								@change="handleChangeName($event, address)"
								@keydown.enter="handleKeyDownEnter($event, address)"
							/>
						</b-form-group>
						<b-form-input
							v-if="index > 0"
							v-model="address.address.name"
							:placeholder="FormMSG(458, 'Enter a name')"
							@change="handleChangeName($event, address)"
							@keydown.enter="handleKeyDownEnter($event, address)"
						/>
					</b-col>
					<b-col cols="8">
						<b-form-group v-if="index === 0" :label="FormMSG(616, 'Address')" class="mb-0">
							<b-input-group>
								<b-form-input class="br-none" :value="formatAddress(address.address)" :placeholder="FormMSG(459, 'No selected address...')" />
								<b-input-group-text class="bg-color-white input-group-text-bg-white">
									<button type="button" class="btn-transparent text-color-fly-away" @click="rendGoogleMapLink(address.address)">
										<component :is="getLucideIcon('MapPin')" :size="20" />
									</button>
									<button type="button" class="btn-transparent text-color-aqua-velvet" @click="handleClickAddAddress(address)">
										<component :is="getLucideIcon('PlusCircle')" :size="20" />
									</button>
								</b-input-group-text>
							</b-input-group>
						</b-form-group>
						<b-input-group v-if="index > 0">
							<b-form-input class="br-none" :value="formatAddress(address.address)" :placeholder="FormMSG(459, 'No selected address...')" />
							<b-input-group-text class="bg-color-white input-group-text-bg-white">
								<button type="button" class="btn-transparent text-color-fly-away" @click="rendGoogleMapLink(address.address)">
									<component :is="getLucideIcon('MapPin')" :size="20" />
								</button>
								<button type="button" class="btn-transparent text-color-aqua-velvet" @click="handleClickAddAddress(address)">
									<component :is="getLucideIcon('PlusCircle')" :size="20" />
								</button>
							</b-input-group-text>
						</b-input-group>
					</b-col>
				</b-row>
			</div>
			<div class="text-center ml-3" :class="index === 0 ? 'pt-29' : 'pt-1'">
				<button type="button" class="btn-transparent text-color-burning-tomato-2" @click="handleClickRemove(address)">
					<component :is="getLucideIcon('MinusCircle')" :size="20" />
				</button>
			</div>
		</div>
		<div v-if="showNewOffsetLocationBtn" class="w-90 mt-3">
			<div class="float-right">
				<button type="button" class="btn-transparent text-color-rhapsody-in-blue fs-14 fw-700" @click="handleNewOffsetLocation">
					<component :is="getLucideIcon('PlusCircle')" :size="20" color="#00A09C" />
					{{ FormMSG(617, 'New off-set location') }}
				</button>
			</div>
		</div>

		<b-modal
			header-class="header-class-modal-doc-package"
			ref="modal"
			v-model="openOffsetLocationModal"
			:title="FormMSG(547, 'Off-set location')"
			size="xl"
			ok-variant="success"
			:ok-title="FormMSG(2, 'Save')"
			:cancel-title="FormMSG(3, 'Cancel')"
			@ok="handleOk"
			@cancel="handleCancel"
		>
			<AddressForm :edit-data="addressLocationToEdit.address" @change="handleAddressFormChange" />
		</b-modal>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { addressFormatter } from '@/shared/fomaters';
import { newAddress, delAddress, updateAddress } from '@/cruds/address.crud';
import AddressForm from '@/components/Forms/Address';
import _ from 'lodash';

const addressList = [
	'addressId1',
	'addressId2',
	'addressId3',
	'addressId4',
	'addressId6',
	'addressId7',
	'addressId8',
	'addressId9',
	'addressId10',
	'addressId11',
	'addressId12',
	'addressId13',
	'addressId14'
];

const addressListAddress = {
	addressId1: 'address1',
	addressId2: 'address2',
	addressId3: 'address3',
	addressId4: 'address4',
	addressId6: 'address6',
	addressId7: 'address7',
	addressId8: 'address8',
	addressId9: 'address9',
	addressId10: 'address10',
	addressId11: 'address11',
	addressId12: 'address12',
	addressId13: 'address13',
	addressId14: 'address14'
};

export default {
	name: 'OffsetLocations',

	props: {
		dataToEdit: {
			type: Object,
			default: {},
			required: true
		}
	},

	components: {
		AddressForm
	},

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			currentLocation: {},
			addressLocationList: [],
			addressAlreadyUsed: [],
			dataToEmit: {},

			isAddressModalOpen: false,
			addressLocationToEdit: {},
			addressOriginal: {},

			openOffsetLocationModal: false
		};
	},

	watch: {
		dataToEdit: {
			async handler(newVal) {
				this.addressLocationList = [];
				this.currentLocation = newVal;
				this.dataToEmit = {};
				this.addressAlreadyUsed = [];

				this.initAddressList(newVal);
				// if (this.addressLocationList.length === 0) {
				// 	const res = await newAddress();
				// 	this.addressLocationList.push({
				// 		model: 0,
				// 		key: 'addressId1',
				// 		address: res
				// 	});
				// 	this.addressAlreadyUsed.push('addressId1');
				// 	this.dataToEmit = {
				// 		addressId1: parseInt(res.id)
				// 	};
				//
				// 	this.emitEventChange();
				// }
			},
			immediate: true
		}
	},

	computed: {
		showNewOffsetLocationBtn() {
			if (this.addressLocationList.length < addressList.length) {
				return true;
			}

			return false;
		}
	},

	methods: {
		rendGoogleMapLink(address) {
			const { lat, lng } = address;
			if (_.isNil(lat) || _.isNil(lng)) return '';

			window.open(
				`http://www.google.com/maps/place/${lat},${lng}`,
				this.FormMSG(213421, 'Google map link'),
				'height=670,width=992,modal=yes,alwaysRaised=yes'
			);
		},
		handleClickAddAddress(payload) {
			this.addressLocationToEdit = payload;
			this.addressOriginal = _.cloneDeep(payload.address);
			this.openOffsetLocationModal = true;
		},
		async handleOk() {
			const fieldToSend = _.omit(this.addressLocationToEdit.address, ['id', 'createdAt', 'updatedAt', 'projectId', '__typename']);
			const result = await updateAddress(this.addressLocationToEdit.addressId, { ...fieldToSend });
			const addressLocationListMaped = this.addressLocationList.map((option) => {
				if (option.addressId === this.addressLocationToEdit.addressId) {
					return {
						addressId: this.addressLocationToEdit.addressId,
						key: this.addressLocationToEdit.key,
						address: result
					};
				}
				return {
					...option
				};
			});
			this.addressLocationList = addressLocationListMaped;
			this.addressLocationToEdit = {};
		},
		async handleCancel() {
			const addressLocationListMaped = this.addressLocationList.map((option) => {
				if (option.addressId === this.addressLocationToEdit.addressId) {
					return {
						addressId: this.addressLocationToEdit.addressId,
						key: this.addressLocationToEdit.key,
						address: this.addressOriginal
					};
				}
				return {
					...option
				};
			});
			this.addressLocationList = addressLocationListMaped;
			this.openOffsetLocationModal = false;
		},
		handleAddressFormChange(payload) {
			this.addressLocationToEdit.address = {
				...this.addressLocationToEdit.address,
				...payload
			};
		},
		async handleChangeName(payload, address) {
			address.address = await updateAddress(address.addressId, {
				name: payload
			});
		},
		async handleKeyDownEnter(payload, address) {
			address.address = await updateAddress(address.addressId, {
				name: address.address.name
			});
		},
		async handleClickRemove(payload) {
			await delAddress(payload.addressId);

			this.$emit('offset-locations:remove', {
				[payload.key]: 0
			});
		},
		async handleNewOffsetLocation() {
			for (let i = 0; i < addressList.length; i++) {
				if (!this.addressAlreadyUsed.includes(addressList[i])) {
					const res = await newAddress();
					this.addressAlreadyUsed.push(addressList[i]);
					this.dataToEmit = {
						...this.dataToEmit,
						[addressList[i]]: parseInt(res.id)
					};
					break;
				}
			}
			this.emitEventChange();
		},
		initAddressList(payload) {
			for (const [key, value] of Object.entries(payload)) {
				if (addressList.includes(key) && value > 0) {
					this.addressLocationList.push({
						addressId: parseInt(value),
						key,
						address: payload['' + addressListAddress[key]]
					});
					this.addressAlreadyUsed.push(key);
					this.dataToEmit = {
						...this.dataToEmit,
						[key]: parseInt(value)
					};
				}
			}
			// this.emitEventChange();
		},
		formatAddress(payload) {
			return addressFormatter(payload);
		},
		emitEventChange() {
			this.$emit('offset-locations:change', this.dataToEmit);
		}
	}
};
</script>

<style scoped></style>
