<template>
	<div class="row bootstrap-date-selection">
		<label class="col-sm-3" for="location_date">{{ FormMSG(1, 'Map picture') }}</label>
		<div class="col-sm-9 px-0 d-flex align-items-center">
			<div class="col-sm-6">
				<b-button v-if="!$isPwa()" variant="outline-primary" @click="openElectPicModalEnd" style="margin-left: -4px" class="w-50">
					{{ FormMSG(2, 'Add map picture') }}
				</b-button>
				<b-button v-if="$isPwa()" variant="outline-primary" @click="startMobilePwaCapture" :disabled="isStartMobilePwaCaptureLoading" class="w-50">
					<b-spinner v-if="isStartMobilePwaCaptureLoading" variant="primary" small style="margin-right: 10px" />
					{{ FormMSG(2, 'Add map picture') }}
				</b-button>
			</div>
			<div class="col-sm-6">
				<b-button
					class="d-flex justify-content-center align-items-center float-right w-50"
					variant="outline-secondary"
					v-if="mapPicturePayload !== ''"
					size="md"
					@click="showPicture([mapPicturePayload])"
				>
					<component :is="getLucideIcon(ICONS.PICTURE.name)" :size="20" class="mr-2" />
					<div style="margin-top: 1px">{{ FormMSG(3, 'view picture') }}</div>
				</b-button>
			</div>
		</div>
		<Capture
			v-model="isMapPictureModalOpen"
			auto-crop-capture
			only-one-crop-mode
			:parentId="parentId"
			parentType="location"
			parentSubType="location_map"
			@change="handleMapPictureChange"
		/>
	</div>
</template>

<script>
import { makeID, isNil } from '@/shared/utils';
import { Camera, CameraResultType } from '@capacitor/camera';
import { store } from '@/store';
import { getFileExtension } from '@/shared/helpers';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'IncludeMapPictureComponent',
	components: {
		Capture: () => import('@/components/CaptureModule/Main')
	},
	mixins: [languageMessages, globalMixin],
	props: {
		picture: {
			type: String,
			required: false,
			default: null
		},
		parentId: {
			type: String,
			require: false,
			default: ''
		}
	},
	data() {
		return {
			mapPicturePayload: '',
			isStartMobilePwaCaptureLoading: false,
			isMapPictureModalOpen: false
		};
	},
	computed: {
		/**
		 * @param {String} name
		 * @return {String}
		 */
		rendRef() {
			return `_map_picture_${makeID(5)}`;
		}
	},
	async created() {
		await this.initEditMode();
	},
	methods: {
		async startMobilePwaCapture() {
			this.isStartMobilePwaCaptureLoading = true;

			const photo = await Camera.getPhoto({
				resultType: CameraResultType.Uri,
				allowEditing: true,
				quality: 100
			});

			const blob = await fetch(photo.webPath).then((r) => r.blob());
			let formData = new FormData();
			formData.append('uploadimage', blob);

			// const { parentType, parentSubType, parentId } = this.captureOptions
			// if (!isNil(parentType)) formData.append('parentType', parentType)
			// if (!isNil(parentSubType)) formData.append('parentSubType', parentSubType)
			if (!isNil(this.parentId)) formData.append('parentId', this.parentId);

			try {
				const pic = await this.$axios.$post('upload', formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				});
				this.handleMapPictureChange([pic]);
			} catch (e) {
				console.error({ e });
			}

			this.isStartMobilePwaCaptureLoading = false;
		},

		/**
		 * @param {Array} images
		 */
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true
				}
			});
		},

		initEditMode() {
			if (isNil(this.picture)) return;
			this.mapPicturePayload = this.picture;
		},

		openElectPicModalEnd() {
			this.isMapPictureModalOpen = true;
		},
		/**
		 * @param {Array} imgs
		 */
		handleMapPictureChange(imgs) {
			this.mapPicturePayload = imgs[0];
			this.$emit('change', {
				key: 'mapPicture',
				img: imgs[0]
			});
		}
	}
};
</script>
