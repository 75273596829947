var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "location-details-item-mobile" },
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "p-0" }, [
            _c("h6", {
              staticClass: "fw-600",
              domProps: { innerHTML: _vm._s(_vm.rendAddressTitle) },
            }),
          ]),
          _c("div", { staticClass: "pt-2 pb-3" }, [
            _c("div", { staticClass: "title" }, [
              _vm.checkAddress
                ? _c("div", { staticClass: "title_address" }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.rendFilledAddress) },
                    }),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "same-address lh-20" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    attrs: {
                      id: _vm.makeItemRef("dynamic_adrress_same_lcoation"),
                      name: "dynamic_adrress_same_lcoation",
                    },
                    model: {
                      value: _vm.isSameAddress,
                      callback: function ($$v) {
                        _vm.isSameAddress = $$v
                      },
                      expression: "isSameAddress",
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(21, "Same as location address")) +
                        "\n\t\t\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              !_vm.isSameAddress
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "success m-0", size: "sm" },
                              on: { click: _vm.openAdresseModal },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.checkAddress
                                      ? _vm.FormMSG(2, "Edit")
                                      : _vm.FormMSG(1, "Add")
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm.checkAddress
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "ml-2",
                                  attrs: { variant: "danger", size: "sm" },
                                  on: { click: _vm.handleDeleteAddress },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(3, "Remove")) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.rendAddressTitle,
            size: "xl",
            "ok-variant": "success",
            "ok-title": "Save",
            "cancel-title": "Cancel",
          },
          on: {
            ok: _vm.handleAddressSubmit,
            cancel: _vm.handleAdressModalCancel,
            hidden: _vm.handleAdressModalCancel,
          },
          model: {
            value: _vm.isAddressModalOpen,
            callback: function ($$v) {
              _vm.isAddressModalOpen = $$v
            },
            expression: "isAddressModalOpen",
          },
        },
        [
          _c("AddressForm", {
            attrs: { "edit-data": _vm.editAddressValue },
            on: { change: _vm.handleAdressChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }