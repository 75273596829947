var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { location_service_images_includes_container: !_vm.carouselMode },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("CapturesPackage", {
                attrs: {
                  multiple: "",
                  "parent-type": "location",
                  "parent-sub-type": "images",
                  "preview-mode": "card",
                  "carousel-mode": _vm.carouselMode,
                  "parent-id": _vm.parentId,
                  pictures: _vm.imagesPayload,
                },
                on: { change: _vm.handleImgsTaked },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }