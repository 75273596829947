import { isNil } from '@/shared/utils';

export default {
	name: 'DynamicAddressItemMixin',
	props: {
		setAddressId: {
			type: Number,
			required: false,
			default: 0
		}
	},
	data() {
		return {
			isSameAddress: false
		};
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendAddressTitle() {
			return this.params.title;
		},

		/**
		 * @return {String}
		 */
		isSavedSameAddress() {
			if (isNil(this.addressId) || this.addressId === 0) return false;
			return this.setAddressId === this.addressId;
		}
	},
	watch: {
		/**
		 * @param {Boolean} status
		 */
		isSameAddress(status) {
			if (!status && this.addressId !== 0) this.removeDifferentAddress();

			if (status) {
				if (this.setAddressId === 0) return;
				this.$emit('change', {
					id: this.setAddressId,
					key: this.params.name
				});
				this.getAddress(this.setAddressId);
			}
		}
	},
	created() {
		this.isSameAddress = this.isSavedSameAddress;
	},
	methods: {
		removeDifferentAddress() {
			if (!this.isSavedSameAddress) return;
			this.$emit('change', {
				id: 0,
				key: this.params.name
			});
			this.addressPayload = {};
		}
	}
};
