<template>
	<div :class="{ location_service_images_includes_container: !carouselMode }">
		<b-row>
			<b-col>
				<CapturesPackage
					multiple
					parent-type="location"
					parent-sub-type="images"
					preview-mode="card"
					:carousel-mode="carouselMode"
					:parent-id="parentId"
					:pictures="imagesPayload"
					@change="handleImgsTaked"
				/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { isNil } from '@/shared/utils';
import { mapFilters } from '~filters';
import languageMessages from '@/mixins/languageMessages';
import CapturesPackage from '@/components/Packages/Captures/components/Main';

export default {
	name: 'LocationServiceImagesIncludeComponent',
	components: { CapturesPackage },
	mixins: [languageMessages],
	props: {
		carouselMode: {
			type: Boolean,
			default: false,
			required: false
		},
		images: {
			type: Array,
			required: false,
			default: null
		},
		parentId: {
			type: [Number, String],
			required: false,
			default: null
		}
	},
	data() {
		return {
			imagesPayload: [],
			isImgsModalOpen: false
		};
	},
	computed: {
		isEditMode() {
			return !isNil(this.images);
		}
	},
	watch: {
		images: {
			handler(newVal) {
				this.initEditing();
			},
			immediate: true,
			deep: true
		}
	},
	async created() {
		await this.initEditing();
	},
	methods: {
		...mapFilters(['pluralize']),

		initEditing() {
			if (!this.isEditMode) return;
			this.imagesPayload = [...this.images];
		},
		openImagesModal() {
			this.isImgsModalOpen = true;
		},

		/**
		 * @param {Array} images
		 */
		handleImgsTaked(images) {
			this.imagesPayload = images;
			this.$emit('change', images);
		},

		/**
		 * @param {Number} count
		 * @return {String}
		 */
		rendPictureLabel(count) {
			const _t = this.FormMSG;
			const p = this.pluralize('picture', count);
			return `${_t(43341, 'view')} ${count} ${_t(43342, p)}`;
		}
	}
};
</script>
